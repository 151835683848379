body {
  background-color: rgb(15, 15, 15) !important;
  color: azure !important;
}

.title-div {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* column-gap: 3px; */
  /* position: relative; */
  margin: 0 auto;
  /* margin-top: 3rem; */
  /* flex-direction: column; */
}
.cardWrapper {
  overflow: hidden;
}

.title-image {
  width: 50vw;
  /* height: 240px;
  object-fit: cover; */
  margin: auto;
  border-radius: 12%;
  /* position: absolute; */
}

#store-name {
  text-shadow: 2px 2px 2px red;
  animation: nameanim 2s infinite;
}

@keyframes nameanim {
  0% {
    text-shadow: 2px 2px 4px red;
  }
  50% {
    text-shadow: 2px 2px 4px blue;
  }
  100% {
    text-shadow: 2px 2px 4px green;
  }
}

.games-div {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.each-game {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 1rem;
}

.game-image {
  width: 70vw;
  border-radius: 5%;
}

.footer-div {
  width: 100vw;
  padding: 9px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  /* position: sticky; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* flex-direction: column; */
}

.mlbb-image {
  width: 100vw;
  height: 45vh;
  position: sticky;
  z-index: 1;
  top: 0px;
  object-fit: cover;
}

.bigmargin {
  margin-top: -1vh !important;
  /* border-top-right-radius: 25px; */
  /* border-top-left-radius: 25px; */
}

.maincontentdiv {
  position: relative;
  z-index: 2;
  padding: 1rem 2rem;
  margin: 1rem;
  text-align: center;

  color: black;

  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);

  background-color: white;
  border-radius: 25px;
  /* border: 1px solid rgba(209, 213, 219, 0.3); */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.BottomNavBar {
  width: 100vw;
  height: 10vh;

  position: fixed;
  bottom: 0;
  z-index: 300;

  background-color: #000000d6;

  display: flex;
  justify-content: space-around;
}

.new-navbar {
  position: fixed;
  z-index: 100;
  bottom: 2rem;
  left: 2%;
  width: 96vw;
  height: 3rem;
  /* margin: auto; */

  border-radius: 50px;
  color: white;
  background-color: rgb(96, 96, 96);

  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  cursor: pointer;
}

a {
  text-decoration: none;
  color: white;
}

.card .inner {
  /* padding: 25px; */
  /* background: #222; */
  color: #fff;
  /* border-radius: var(--border-radius); */
}

.example-4 {
  outline-width: 1px;
  outline-offset: 0;
  outline-color: #08f;
  outline-style: solid;
  animation: animateOutline 4s ease infinite;
}

@keyframes animateOutline {
  0% {
    outline-width: 1px;
    outline-offset: 0;
    outline-color: rgba(0, 130, 206, 0);
  }

  10% {
    outline-color: #08f;
  }

  /* The animation finishes at 50% */
  50% {
    outline-width: 7px;
    outline-offset: 4px;
    outline-color: rgba(0, 130, 206, 0);
  }

  100% {
    outline-width: 7px;
    outline-offset: 4px;
    outline-color: rgba(102, 102, 102, 0);
  }
}


.main-div {
  width:40vw;
  height: auto;
  margin: auto;
  padding: 5%;
  color: white;
  position: relative;
  z-index: 2;
  text-align: center;

  border: 3px solid white;
}

.info-container{
  width: 90%;
  height: auto;
  min-height: 20vh;
  margin: auto;
  padding:3%;
  background-color: #00f0ff;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.full-screen {
  position: relative;
  z-index: 10;
  margin-top: -6vh;
}

.recharge-div{
  width: 41%;
  height: auto;
  min-height: 7rem;
  font-size:  1rem;
  color:white;
  background-color: black;
  border-radius: 16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display:flex;
  flex-direction:column;
  justify-content: space-around;
  align-items:center;
  margin-bottom:1rem;
  padding:0.5rem;

}

.selected{
  outline: 4px dashed black;
  transition:all 200ms;
  transition-timing-function: ease;
  width:48%;
}

.recharge-items{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}


.bannerimg{
  width: 100%;
  max-width: fit-content;
}